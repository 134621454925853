import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import HeadManagement from '~/mixins/HeadManagement';
import { Route } from 'vue-router';
import { Banner } from '~/components/organisms';

import imageHero from '~/assets/images/hero_search.jpg';
import LoginForm from '~/components/templates/user/LoginForm';
import { VueComponentMixin } from '~/utils/vue-component';

@Component
export default class Login extends VueComponentMixin(HeadManagement) {
  protected get bannerTitle(): string {
    return this.$t('app.user.title').toString();
  }

  public beforeRouteEnter(to: Route, from: Route, next: any) {
    LoginForm.setPreviousRoute(from);
    next();
  }

  public render() {
    return (
      <v-container fluid class='pa-0'>
        <Banner
          backgroundImage={{
            src: imageHero,
            alt: this.$t('app.user.title'),
          }}
          isFirst
          title={this.bannerTitle}
          titleLevel={1}
          slim={true}
        />
        <v-container class='czt-spacer'>
          <v-row justify='center' no-gutters>
            <v-col cols='12' lg='8'>
              <LoginForm />
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    );
  }
}
