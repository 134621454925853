import { getPathWithLocale, getSimpleUrlFromPath } from '~/app/core/router';
import { defaultLocale } from '~/app/localization';
import {
  getCDNSrc,
  getSrcSet,
  getWebpSrc,
  setQuality,
} from '~/components/atoms/image/Image';

export enum ROBOTS {
  INDEX_FOLLOW = 'index,follow',
  INDEX_NOFOLLOW = 'index,nofollow',
  NOINDEX = 'noindex',
  NOINDEX_NOFOLLOW = 'none',
}

export enum TYPE {
  ARTICLE = 'article',
  WEBSITE = 'website',
}

export enum SchemaType {
  ARTICLE = 'Article',
  EVENT = 'Event',
}

export const defaultTitle = '#VisitCzechia';

export function createJsonLd({
  type = SchemaType.ARTICLE,
  title,
  image,
  created,
  modified,
  startDate,
  endDate,
}: {
  type?: SchemaType;
  title: string | null;
  image: string[];
  created?: string;
  modified?: string;
  startDate?: string;
  endDate?: string;
}) {
  // TODO: Support event from backend
  if (type === SchemaType.EVENT) {
    return '';
  }
  const schema: any = {
    '@context': 'https://schema.org',
    '@type': type,
    headline: title?.trim() || defaultTitle,
    image,
    author: [
      {
        '@type': 'Organization',
        name: 'CzechTourism',
        url: 'https://www.czechtourism.cz/',
      },
    ],
  };

  // if (type === SchemaType.EVENT) {
  //   schema.name = title?.trim() || defaultTitle;
  //   schema.startDate = startDate;
  //   schema.endDate = endDate;
  // }

  if (created) {
    schema.datePublished = created;
  }
  if (modified) {
    schema.dateModified = modified;
  }
  return `<script type="application/ld+json">
  ${JSON.stringify(schema)}
  </script>`;
}

export default function({
  title,
  description,
  canonicalUrl,
  type = TYPE.ARTICLE,
  image,
  imagesToPreload = [],
  robots = ROBOTS.INDEX_FOLLOW,
  mutations = [],
  path,
}: {
  title: string | null;
  description: string;
  canonicalUrl: string;
  type?: TYPE;
  image?: string;
  imagesToPreload?: string[];
  robots?: ROBOTS;
  mutations?: string[];
  path?: string;
}): string {
  const titleParts = [defaultTitle];
  if (title) {
    titleParts.unshift(title);
  }
  let content = `
<meta name="robots" content="${robots}" />
${canonicalUrl ? `<link rel="canonical" href="${canonicalUrl}" />` : ''}
${canonicalUrl ? `<meta property="og:url" content="${canonicalUrl}" />` : ''}
<meta property="og:type" content="${type}">
<meta name="author" content="CzechTourism" />
<meta name="twitter:creator" content="CzechTourism" />
<meta name="twitter:title" content="${titleParts.join(' · ')}" />
<meta property="og:title" content="${titleParts.join(' · ')}" />
<meta name="description" content="${description}" />
<meta name="twitter:description" content="${description}" />
<meta property="og:description" content="${description}" />
`;

  if (image) {
    image = getCDNSrc(getWebpSrc(image));
    image = `${image.split('?')[0]}?width=768`;
    content += '<meta name="twitter:card" content="summary_large_image">\n';
    content += `<meta name="twitter:image" content="${image}">\n`;
    content += `<meta property="og:image" content="${image}">\n`;
  } else {
    content += '<meta name="twitter:card" content="summary">';
  }

  if (mutations && path) {
    mutations.forEach((mutation) => {
      const currentMutation = mutation.toLowerCase();
      content += `<link rel="alternate" href="${getSimpleUrlFromPath(
        getPathWithLocale(path, undefined, currentMutation)
      )}" hreflang="${
        currentMutation === defaultLocale.toLowerCase()
          ? 'x-default'
          : currentMutation
      }" />`;
    });
  }

  if (imagesToPreload.length > 0) {
    imagesToPreload.forEach((itp) => {
      itp = getCDNSrc(getWebpSrc(setQuality(itp)));
      content += `<link rel="preload" as="image" href="${itp}" imagesizes="100vw" imagesrcset="${getSrcSet(
        itp
      )}">`;
    });
  }

  return content;
}
